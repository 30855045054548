body {
  direction: rtl;
}

h1 {
  margin: 0 0;
  font-size: 6vw;
}

h5 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 100;
}

h7 {
  font-weight: 300;
  font-size: 15px;
  margin-top: 0;
  margin-bottom: 10px;
}

p {
  margin: 0px 0px 30px 0px;
  font-size: 20px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  width: 100%;
  height: 100%;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* If .game-container is the parent */
.game-container {
  width: 100%; /* Ensure it spans the full width */
  display: flex;
  flex-direction: column;
  align-items: center; /* This centers its children horizontally */
}

/* CSS */
.grid {
  display: grid;
  grid-template-rows: repeat(9, 1fr); /* 9 rows for 9 attempts */
  grid-gap: 5px; /* Space between rows */
  max-width: 500px; /* Adjust as needed */
  margin-top: 20px;
  margin-bottom: 0;
}

.row {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 5 columns for 5 letters */
  grid-gap: 5px; /* Space between cells */
}

.cell {
  width: 40px; /* Adjust as needed */
  height: 40px; /* Adjust as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 2px solid #d3d6da; */
  font-size: 34px; /* Adjust font size as needed */
  font-weight: bold;
  color: black;
  background-color: white;
  cursor: pointer;
  position: relative;
  border: 4px solid white;
}

.cell-sure {
  border: 4px solid #7afbff !important;
  border-radius: 10px;
}

.cell-flag-sure {
  border: 2px dotted #000000 !important;
  border-radius: 10px;
  width: 44px;
  height: 44px;
}

.cell-flag-sure > .x {
  top: 1px;
  left: 1px;
}

.cell-correct {
  background-color: #6aaa64 !important; /* Green for correct position */
  border: 4px solid #6aaa64;
}

.cell-misplaced {
  background-color: #c9b458 !important; /* Yellow for correct letter, wrong position */
  border: 4px solid #c9b458;
}

.cell-wrong {
  background-color: #787c7e; /* Grey for incorrect letter */
  border: 4px solid #787c7e;
}
/* Add this to your CSS file (e.g., App.css) */

/* Add this to your CSS file */

.keyboard {
  user-select: none;
  margin: 0 auto; /* Centers the keyboard */
  margin-bottom: 100px;
  max-width: 500px;
  width: 90vw;
}

.keyboard-row {
  display: block; /* Align keys horizontally */
  margin: 8px 0; /* Adds margin between rows */
}

.key {
  background-color: #f0f0f0;
  border: 2px solid #ccc;
  border-radius: 0px;
  margin: 0 2px; /* Reduced space between keys */
  padding: 1px 5px; /* Smaller padding for keys */
  font-size: 28px; /* Smaller font size */
  cursor: pointer;
  transition: background-color 0.2s, transform 0.1s;
  width: 8%;
  aspect-ratio: 1/1.1;
  text-align: center;
}

.key-sure {
  border: 4px solid #7afbff !important;
  border-radius: 10px;
}

.enter {
  width: 20%;
  font-size: 80%;
  aspect-ratio: 1.9;
}

.backspace {
  width: 12.5%;
  font-size: 90%;
  aspect-ratio: 1.19;
}

.key-correct {
  background-color: #6aaa64 !important;
  border: 2px solid #6aaa64;
}

.key-misplaced {
  background-color: #c9b458 !important;
  border: 2px solid #c9b458;
}

.key-wrong {
  background-color: #787c7e !important;
  border: 2px solid #787c7e;
}

.key:active {
  background-color: #e0e0e0;
  transform: scale(0.95);
}

/* Optional: Add media queries to ensure responsiveness on smaller screens */
@media (max-width: 768px) {
  .key {
    padding: 4px 8px; /* Even smaller padding for smaller screens */
    font-size: 14px; /* Smaller font size for smaller screens */
  }
}

.clickX {
  font-size: 15px;
  margin-top: 5px;
}

.x {
  display: inline-block; /* Makes the image behave like an inline element */
  vertical-align: middle; /* Optional: Aligns the image vertically with the surrounding text */
  width: 20px; /* Adjust the size as necessary */
  height: 20px; /* Adjust the size as necessary */
}

.x-corner {
  position: absolute; /* Absolutely position the x image relative to its nearest positioned ancestor (the .cell) */
  top: -1px; /* Align to the top of the cell */
  left: -0.5px; /* Align to the left of the cell */
  width: 15px; /* Adjust size as necessary */
  height: 15px; /* Adjust size as necessary */
}

.hidden {
  display: none;
}

.error {
  color: red;
}

.winPopup-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.winPopup-container {
  background-color: #282c34;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  color: white;
}

.winPopup-container h1 {
  font-size: 50px;
}

.winPopup-todaysWord {
  font-weight: bold;
  font-size: 22px;
  display: inline;
}

.winPopup-text {
  font-size: 16px;
}

.shareButton {
  width: 45px;
  height: 45px;
  margin: 0 5px;
  cursor: pointer;
}

.shareTitle {
  margin-bottom: 10px;
  font-size: medium;
}
.adsbygoogle {
  min-width: 320px; /* or any other minimum width */
  display: block; /* Ensure it's not set to none */
}

.instructions-link {
  font-size: 15px;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 0px;
  margin-bottom: 10px;
}
.closeButton {
  width: 35px;
  aspect-ratio: 1;
  position: relative;
  top: 0;
  left: 0;
  cursor: pointer;
}

.text-sure {
  color: #7afbff;
  font-weight: bold;
}

.logo-liar {
  width: 9vw;
  height: 9vw;
}

.logo {
  width: 100%;
  display: flex;
  margin-top: 30px;
  justify-content: center;
  flex-wrap: wrap;
}
